.label_field_editor {
  font-weight: bold;
  margin-top: 8px;
}

.seperator_field_editor {
  opacity: 0.2;
  margin: 8px;
  margin-bottom: 15px;
}
