.footer {
  display: flex;
  justify-content: space-between;
}

.custom_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .4rem;
  margin-left: auto;
}
