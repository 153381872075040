.produktionsplan_table {
  width: 100%;

  td, th {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  th {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  td {
    border-left: 0.5px dashed black;
    border-right: 0.5px dashed black;
  }
}

.main_header_row {
  th {
    text-align: center !important;
    background-color: rgb(180, 180, 180);
  }
}

.produktionsplan_title {
  padding-bottom: 0;
}

.blasmaschine_nummer {
  text-align: center !important;
  border-right: 0;
  padding-bottom: 0;
  background-color: rgb(180, 180, 180);
}

h3, h4 {
  margin: 0 !important;
}

.align_right {
  text-align: right !important;
}

.align_center {
  text-align: center !important;
}

.empfaenger_name {
  font-size: 12px;
}

.cell_with_dashed_line {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cell_with_dashed_line__grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.cell_with_dashed_line__top {
  padding-left: 11px;
  padding-right: 11px;
}

.cell_with_dashed_line__bottom {
  padding-left: 11px;
  padding-right: 11px;
  border-top: 1px dashed black;
  text-align: right;
}

.granulat_farbe_nummer_cell {
  padding-left: 18px;
  padding-right: 11px;
  border-top: 1px dashed black;
}

.artikel_gewicht_cell {
  padding-left: 11px;
  padding-right: 11px;
  text-align: right;;
}

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}
