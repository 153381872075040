.logo {
  width: 15rem;
  margin-bottom: 0.5rem;
}

.page_background {
  width: 100%;
  height: 100%;
  background-color: #993366;
  padding: 0.5rem 1rem;
}

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_title {
  color: white;
}

.header_spacer {
  width: 15rem;
}

.mlp_vorschau_table {
  background-color: white;
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;

  tr {
    td,
    th {
      border: 1px solid black;
      padding: 0.1rem 0.2rem;
      font-size: small;
    }

    td {
      background-color: #e3e3e3;
      color: #9c3163;
      font-weight: bold;
    }

    th {
      background-color: #969696;
      font-weight: normal;
    }
  }

  .kuau_pau_au_art_row {
    td {
      vertical-align: middle;
      font-size: 1.5rem;
      width: 12rem;
    }
  }

  .kuau_pau_au_art_header {
    height: 4rem;
    min-width: 15rem;
    font-weight: bolder;
    vertical-align: middle;
    background-color: #c0c0c0;
  }

  .kundenauftrag_art {
    color: #0d8284;
    background-color: white;
    width: 1.5rem !important;
  }

  .toleranz {
    text-align: center;
    background-color: #ffffff;
  }

  .blasmaschine_nr_header {
    font-weight: bolder;
    font-size: 1.5rem;
    text-align: center;
    vertical-align: middle;
    height: 4rem;
  }

  .blasmaschine_nr {
    text-align: center;
  }

  .liefertermin {
    min-width: 14rem;
  }

  .transport_header {
    height: 2rem;
    td,
    th {
      background-color: #2eff00;
    }
  }

  .transport_weg,
  .fahrzeug {
    background-color: #2eff00;
    color: black;
    font-size: 1rem;
    font-weight: bolder;
    text-align: center;
    border: 3px solid black;
  }

  .verpackung_row {
    td {
      color: #0d8284;
      font-weight: bolder;
      text-align: center;
    }
  }

  .vpe {
    font-size: 1rem;
  }

  .ku_vorgaben {
    background-color: #16d2ff;
    text-align: center;
    font-weight: bolder;
    border: 2px solid black;
  }

  .anzahl_lagen_vorgabe,
  .hoehe_stapel_vorgabe {
    background-color: #16d2ff;
    text-align: center;
  }

  .anzahl_stapel_flaschen_header {
    text-align: end;
  }

  .anzahl_flaschen_header {
    color: #0201fe;
  }

  td.anzahl_stapel {
    background-color: #9ccf00;
    color: black;
    padding: 0;
  }

  td.anzahl_flaschen,
  td.anzahl_kartons {
    border: 3px solid #128200;
    color: #0000ff;
  }

  td.folie_material_bezeichnung,
  td.kantenschutz_material_bezeichnung {
    color: black;
    text-align: left;
  }

  .stellplaetze {
    font-size: small;
  }

  td.bemerkung_header {
    background-color: #ffcf01;
    color: black;
    font-weight: normal;
    text-align: left;
  }

  td.bemerkung {
    background-color: #ffff00;
    color: black;
    font-weight: normal;
    text-align: left;
  }

  .text_right_align {
    text-align: right;
  }

  .text_left_align {
    text-align: left !important;
  }

  .white_bar {
    color: white;
  }

  .gray_box {
    background-color: #969696;
  }

  .empty_cell {
    background-color: #ffffff;
    border: none;
    height: 1.2rem;
  }

  .width_third {
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
  }

  .row_fill {
    height: 100%;
  }
}
