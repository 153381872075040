.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

.begleitdokument_page_container {
  font-family: monospace;
  letter-spacing: -0.03rem;
  width: 21cm;
  height: 29.7cm;
  padding-left: 1.9cm;
  padding-right: 1.3cm;
  padding-top: 1.5cm;
  padding-bottom: 1.5cm;
}

.begleitdokument_table {
  border-collapse: collapse;
  width: 100%;

  .right {
    text-align: right !important;
  }

  tr,
  td,
  th {
    padding: 0.1rem 0.2rem !important;
    vertical-align: bottom !important;
    white-space: nowrap !important;
}

  thead {
    tr,
    th,
    td {
      font-weight: normal;
      height: 1.3rem !important;
    }

    font-size: large;

    .header_date {
      text-align: right;
    }
  }

  .druck_content {
    height: 8rem !important;
  }

  tbody {
    font-weight: bold;
    font-size: 2.2rem;
    border: 2px solid black;

    td {
      border-bottom: 2px solid black;
      text-align: center;
      height: 3rem !important;
    }
  }

  tfoot {
    font-weight: bold;
    font-size: 2.5rem;

    th {
      padding-top: 2.2cm !important;
    }
  }
}
