.dashboard_columns {
  display: grid;
  grid-template-areas:
    "stammdaten_navigator auftragsabwicklung_navigator stellblatt_navigator"
    "stammdaten_navigator verpackung_navigator stellblatt_navigator";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 5fr 4fr;
  gap: 10px;
  height: 100%;
  width: 100%;
}

.auftragsabwicklung_navigator,
.stammdaten_navigator,
.stellblatt_navigator,
.verpackung_navigator {
  background-color: #f3f3f3;
}

.auftragsabwicklung_navigator div,
.stammdaten_navigator div,
.verpackung_navigator div,
.stellblatt_navigator div {
  margin-bottom: 10px;
  background-color: rgb(211, 211, 211);
}

.stammdaten_navigator,
.auftragsabwicklung_navigator,
.stellblatt_navigator,
.verpackung_navigator {
  overflow: scroll;
}

.auftragsabwicklung_navigator {
  grid-area: auftragsabwicklung_navigator;
}

.stammdaten_navigator {
  grid-area: stammdaten_navigator;
}

.auftrag_navigator_title,
.stammdaten_navigator_title,
.stellblatt_navigator_title,
.verpackung_navigator_title {
  padding: 0.5rem;
  text-align: center;
  text-decoration: 2px underline;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: gray !important;
}

.stellblatt_navigator {
  grid-area: stellblatt_navigator;
}

.verpackung_navigator {
  grid-area: verpackung_navigator;
}
