.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr,
  td,
  th {
    page-break-inside: avoid;
    break-inside: avoid;
    white-space: nowrap;
    width: min-content;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

$small_font_size: 1.3rem;
$medium_font_size: 1.4rem;
$large_font_size: 1.8rem;
$xl_font_size: 3.3rem;

.rueckmeldung2_page_container {
  font-family: monospace;
  letter-spacing: -0.03rem;
  width: 21cm;
  height: 29.7cm;
  padding-left: 2cm;;
  padding-right: 1.2cm;
  padding-top: 1.4cm;
  padding-bottom: 0.4cm;
}

.rueckmeldung2_table {
  border-collapse: collapse;
  width: 100%;
  font-size: $medium_font_size !important;

  .right {
    text-align: right !important;
  }

  .center {
    text-align: center !important;
  }

  .tall {
    height: 2rem !important;
  }

  .small {
    font-size: $small_font_size !important;
  }

  .large {
    font-size: $large_font_size !important;
  }

  .bold {
    font-weight: bold !important;
  }

  .normal {
    font-weight: normal !important;
  }

  .underline {
    text-decoration: 2px solid underline !important;
  }

  .border_x {
    border-left: 2px solid black !important;
    border-right: 2px solid black !important;
  }

  .border_y {
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
  }

  .no_border_y {
    border-top: none !important;
    border-bottom: none !important;
  }

  tr,
  td,
  th {
    padding: 0.1rem 0.2rem !important;
    width: 10rem !important;
    height: $medium_font_size;
    vertical-align: bottom !important;
  }

  .fertige_produktion_header {
    text-align: center;
    font-size: $xl_font_size;
    font-weight: bold;
    padding-bottom: 0.6cm !important;
    padding-top: 2.2cm !important;
  }

  tbody {
    tr {
      @extend .border_y;
    }

    th {
      font-weight: normal !important;
      border-right: 2px solid black;
    }

    td {
      font-weight: bold !important;
    }

    .volumen_header {
      @extend .right;
      @extend .bold;
      @extend .small;
    }

    .bemerkungen {
      height: 4cm;
      border: none;
      padding-bottom: 7rem !important;
    }
  }

  tfoot {
    .stueckzahl_geprueft_gezaehlt {
      @extend .underline;
      @extend .center;
    }

    .datum_name_stueckzahl {
      @extend .large;
      border-bottom: 2px solid black;
    }

    tr :nth-child(2) {
      @extend .border_x;
    }

    tr td:nth-child(2) {
      height: 3rem;
    }
  }
}
