.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr,
  td,
  th {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

$gray_bg: #d9d9d9;
$small_font_size: 0.7rem;
$medium_font_size: 0.8rem;
$large_font_size: 1rem;
$xl_font_size: 1.25rem;

.rueckmeldung1_page_container {
  width: 29.7cm;
  height: 21cm;
  font-size: $medium_font_size;
  font-family: monospace;
  letter-spacing: -0.03rem;
  padding-left: 0.9cm;
  padding-right: 0.6cm;
  padding-top: 0.4cm;
  padding-bottom: 0.8cm;
}

.rueckmeldung1_table {
  border-collapse: collapse;
  padding: 0 !important;
  padding-left: 1px;
  width: 100%;

  .bold {
    font-weight: bold !important;
  }

  .normal {
    font-weight: normal !important;
  }

  .small {
    font-size: $small_font_size;
  }

  .center {
    text-align: center !important;
  }

  .left {
    text-align: left !important;
  }

  .right {
    text-align: right !important;
  }

  .center_normal {
    @extend .center;
    @extend .normal;
  }

  .center_bold {
    @extend .center;
    @extend .bold;
  }

  .right_normal {
    @extend .right;
    @extend .normal;
  }

  .border {
    border: 1px solid black;
  }

  thead {
    tr,
    td,
    th {
      padding: 0 0.1rem !important;
      margin: 0;
      height: 0.6cm;
      vertical-align: middle;
      white-space: nowrap;
    }

    tr:nth-child(n + 2):nth-child(-n + 8) {
      th {
        font-weight: normal;
      }

      td {
        font-weight: bold;
      }

      th:nth-child(n + 3):nth-child(-n + 4) {
        @extend .border;
        text-align: left;
        border-right: none;
      }

      th:nth-child(n + 5):nth-child(-n + 6) {
        text-align: right;
        border-left: 1px solid black;
      }

      td:nth-child(n + 2):nth-child(-n + 5) {
        text-align: left;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }

      td:nth-child(n + 6):nth-child(-n + 10) {
        @extend .border;
        border-left: none;
        text-align: center;
      }
    }

    .kuau_header,
    .pau_header {
      border: 2px solid black !important;
    }

    .kuau_pau_spacer {
      background-color: $gray_bg;
    }

    .kunde_header {
      font-size: $xl_font_size;
      font-weight: bold !important;
    }

    .rueckmeldung_header,
    .blm_nr_header,
    .blm_nr {
      @extend .center_bold;
      font-size: $large_font_size;
      border-top: 2px solid black !important;
      border-bottom: 2px solid black !important;
    }

    .rueckmeldung_header {
      border-left: 2px solid black !important;
    }

    .blm_nr {
      border-right: 2px solid black !important;
    }

    .zaehler_header {
       font-size: $small_font_size;
       min-width: 3.81cm;
    }

    .prod_gesamt_header {
      min-width: 3.47cm;
    }

    tr.thin_boi {
      height: 0.3cm !important;
      font-size: $small_font_size !important;

      th,
      td {
        @extend .border;
        text-align: center;
        height: 0.3cm !important;
      }

      td {
        font-weight: bold !important;
      }

      td:first-child,
      td:last-child {
        border: none !important;
        border-right: 1px solid black !important;
      }

      th.yellow_cell {
        font-weight: normal;
        background-color: yellow;
      }
    }

    .table_data_header {
      th {
        border: 2px solid black !important;
        text-align: center;
        background-color: $gray_bg;
        vertical-align: middle;
        padding: 0 0.5rem !important;
      }
    }

    .innen_1_header,
    .innen_2_header,
    .innen_3_header,
    .leck_header {
      min-width: 2.53cm;
    }
  }

  tbody {
    td,
    th {
      @extend .border;
      @extend .normal;
      height: 0.63cm;
      padding: 0.1rem !important;
      margin: 0;
      vertical-align: middle;
      white-space: nowrap;
    }

    td:nth-child(2) {
      border: none;
    }
  }
}
