.current_date{
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
}

.bedarf_view {
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.content {
  margin-top: 40px;
  width: 100%;

  table {
    width: 100%;
  }

  thead {
    background-color: rgb(180, 180, 180);
    th {
      border: 1px solid black;
      text-align: end;
    }
  }

  tbody {
    td {
      font-size: small;
    }
  }

  td, th {
    border: 0.5px solid black;
  }
}

.pau_nummer {
  text-align: center !important;
}

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}
