$gray-bg: #e6e6e6;

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

.logo {
  display: flex;
  height: 1cm;
  margin-left: auto;
}

.messinterval {
  position: absolute;
  transform: scale(.25) translate(-43rem, -31rem);
}

.bei_abweichung {
  background-color: beige;
  border: 2px solid black !important;
  font-size: 0.8rem;
  font-weight: bold;
}

.bei_abweichung_pfeil {
  font-size: 0.6rem;
  transform: rotate(135deg);

  div {
    position: absolute;
    transform: scaleX(7) scaleY(2) translate(0.92rem, 1.06rem);
  }
}

.gewichtskontrolle_page_container {
  font-size: 0.8rem;
  font-family: monospace;
  letter-spacing: -0.03rem;
  width: 21cm;
  height: 29.7cm;
  padding-left: 1.3cm;
  padding-right: 0.6cm;
  padding-top: 0.8cm;
  padding-bottom: 1.4cm;
}

.gewichtskontrolle_table {
  border-collapse: collapse;
  display: block;
  padding-left: 1px;

  td,
  th {
    height: 1.22rem;
    padding: 0 0.2rem !important;
    margin: 0 !important;
  }

  .empty_row {
    height: 0.5cm !important;
  }

  td {
    border: 1px solid black;
  }

  th {
    border: 1px solid red;
  }

  thead {
    th,
    td {
      border: none;
      white-space: nowrap;
    }

    th.protokoll_header {
      text-decoration: 2px underline;
      text-align: center;
      vertical-align: bottom;
    }

    th.gewichts_kontrolle_header {
      font-weight: normal;
      text-align: center;
    }

    th.bottom_dash {
      border-bottom: 2px dashed black;
    }

    td.border_fat {
      border: 2px solid black;
      font-weight: bold;
      font-size: large;
    }

    tr.empty_row {
      height: 0.5cm;
    }

    th.gray_bg_center {
      background-color: $gray-bg;
      text-align: center;
      border: 1px solid black;
      vertical-align: middle;
    }

    th.gray_bg_center_border {
      background-color: $gray-bg;
      text-align: center;
      border: 2px solid black;
      vertical-align: middle;
    }

    th.schichtfuehrer_informiert_header {
      background-color: $gray-bg;
      text-align: center;
      border: 1px solid black;
      vertical-align: middle;
      font-weight: normal;
      font-size: small;
    }

    .pau_nummer_header {
      text-align: center;
    }
  }

  td.shorter {
    min-width: 3.5rem !important;
  }

  td.long {
    min-width: 5.55rem !important;
  }

  td.longer {
    width: 100%;
  }

  tfoot tr td {
    padding-top: 1rem !important;
    border: none;
  }
}
