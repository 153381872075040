$gray-bg: #e6e6e6;

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  th, td {
    white-space: normal !important;
    word-wrap: break-word !important;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

.logo {
  position: absolute;
  right: 0cm;
  top: 0cm;
  height: 1.4cm;
  border: 3px solid blue;
}

.page_heading_container {
  position: relative;
}

.pruefprotokoll_page_container {
  width: 21cm;
  height: 29.7cm;
  padding-left: 1.1cm;
  padding-right: 0.6cm;
  padding-top: 0.5cm;
  padding-bottom: 1.1cm;
  font-family: monospace;
  letter-spacing: -0.03rem;

  .page_heading {
    text-align: center;
    width: 100%;
    padding-bottom: 0.4rem;
    font-weight: bold;
    letter-spacing: 0.6rem;
    text-decoration: 2px underline double black;
    font-size: 20px;
    margin-bottom: 9px !important;
  }
}

.pruefprotokoll_table {
  border-collapse: collapse;
  display: block;
  padding-left: 1px;

  td,
  th {
    box-shadow: none !important;
    height: 0.6cm;
    min-width: 1cm;
    padding: 0 0.2rem !important;
    margin: 0 !important;
    font-weight: bold;
    vertical-align: middle !important;
    font-size: 10.69px;
  }

  td {
    border: 1px solid black;
  }

  .normal {
    font-weight: normal;
  }

  .empty_row {
    height: 1cm;
  }

  .paw {
    font-weight: normal;
    vertical-align: middle;
    border-left: none !important;
  }

  .underline {
    text-decoration: 2px underline;
  }

  .bordered_header th {
    border: 1px solid black !important;
    background-color: $gray-bg;
    text-align: center;
    vertical-align: middle;
  }

  .in_ordnung {
    border: none;
    font-size: 13px !important;
    text-align: center;
  }

  .name_unterschrift_header {
    padding-left: 0.6cm !important;
    padding-right: 0.6cm !important;
  }

  .datum_uhrzeit {
    padding-left: 0.5cm !important;
    padding-right: 0.5cm !important;
  }

  .schweis_naehte {
    padding-left: 0cm !important;
    padding-right: 0cm !important;
  }

  .pack_nummer {
    padding-left: 0cm !important;
    padding-right: 0cm !important;
  }

  div.check_box {
    width: 0.6cm;
    height: 0.6cm;
    border: 2px solid black;
    margin: 0 auto;

    .checksign {
      text-align: center;
      font-size: 40px !important;
      transform: translate(-0.1rem, -1.2rem);
    }

    .crosssign {
      text-align: center;
      font-size: 40px !important;
      transform: rotate(45deg) translate(-0.9rem, -0.80rem);
    }
  }

  .fat_border {
    border: 2px solid black !important;
  }

  .fat_border_normal {
    @extend .fat_border;
    @extend .normal;
  }

  .farbe_header {
    @extend .fat_border;
    text-align: right;
  }

  .haeufigkeit_header {
    @extend .fat_border;
    @extend .normal;
    border-right: none !important;
  }

  .haeufigkeit_cell {
    @extend .fat_border;
    border-left: none !important;
    text-align: center;
  }

  .gewichtsoll_header {
    @extend .fat_border;
    border-right: none !important;
  }

  .gewichtsoll_cell {
    @extend .fat_border;
    border-left: none !important;
  }

  .cell_without_border {
    border: none !important;
  }

  .pfeil {
    position: absolute;
    font-size: 3.5rem !important;
    text-align: center;
    transform: rotate(90deg) translate(-105%, -20%) scaleX(0.8);
  }

  .pfeilstrich {
    position: absolute;
    font-size: 3.5rem !important;
    text-align: center;
    transform: rotate(90deg) translate(-128%, -12%) scaleX(0.7) scaleY(1.4);
  }

  thead {
    th,
    td {
      border: 2px solid black;
      white-space: nowrap;
      width: 100%;
    }
  }
}
