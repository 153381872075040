.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  .qhinweise_page_container {
    tr,
    td,
    th {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    body {
      background-color: white !important;
    }

    .print_button {
      display: none;
    }
  }
}

$gray_bg: #d9d9d9;

.qhinweise_page_container {
  padding-left: 0.4cm;
  padding-right: 0.6cm;
  padding-top: 1cm;
  width: 29.7cm;
  font-size: 1rem;
  font-family: monospace;
  letter-spacing: -0.03rem;
}

.qhinweise_table {
  border-collapse: collapse;
  padding: 0;

  thead tr td {
    text-align: center;
    font-weight: bold;
    font-size: large;
  }

  thead tr th {
    text-align: right;
    font-size: large;
  }

  tr:nth-child(7) {
    th {
      background-color: $gray_bg;
      border: 1px solid black;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      font-size: medium !important;
    }

    th.empty_cell {
      background-color: white;
      width: 0.3cm !important;
      border: none;
    }

    th.text_qs_hinweis {
      width: 7cm;
    }

    th.text_korrektur {
      width: 8.3cm;
    }

    th.text_prozesshinweis {
      width: 7.5cm;
    }
  }

  th.lfd_nr_header {
    width: 0.8cm;
    white-space: normal !important;
  }

  th.datum_header {
    width: 1.8cm;
  }

  th.qualitaets_information_header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    background-color: $gray_bg;
    border: 1px solid black !important;
    width: 28.7cm !important;
  }

  td.qualitaets_information {
    height: 1.5rem;
  }

  td,
  th {
    height: 1rem;
    padding: 0.1rem !important;
    margin: 0 !important;
  }

  tr td {
    font-weight: 500;
    text-align: center !important;
    border: 1px solid black;
    font-size: medium !important;
  }

  tr.empty_row {
    height: 0.5rem;
    border: none;
  }

  td.empty_cell {
    width: 0.3cm !important;
    border: none;
    background-color: white !important;
  }

  .gray_bg {
    background-color: $gray_bg;
  }

  tbody tr td {
    height: 3.5rem;

    &.text_cell {
      text-align: left !important;
    }

    &:nth-child(1),
    &:nth-child(6) {
      text-align: right !important;
    }
  }
}
