$gray-bg: #bfbfbf;

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }

  .page_break {
    page-break-before: always;
  }
}

.vpunterschriften_page_container {
  font-size: 0.8rem;
  font-family: monospace;
  letter-spacing: -0.03rem;
  margin: 0;
  border: none !important;
  width: 21cm;
  height: 29.7cm;
  padding-left: 2cm;
  padding-right: 1.8cm;
  padding-top: 1cm;
  padding-bottom: 1.7cm;
}

.vpunterschriften_table {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border-collapse: collapse;
  white-space: nowrap !important;

  tr,
  td,
  th {
    min-height: 0.9cm !important;
  }

  th {
    vertical-align: middle !important;
  }

  tr > th:first-child {
    padding: 0 0.2rem !important;
  }

  tr > th:not(:first-child) {
    padding: 0 1rem !important;
  }

  thead th {
    text-align: center;
    border: 2px solid black;
  }

  tbody th {
    text-align: right;
  }

  tbody td,
  tbody th {
    border: 1px solid black;
    height:  0.95cm !important;
  }

  .page_header {
    font-size: 0.7rem;
    font-weight: normal;
    margin: 0 !important;
    padding: 0 !important;
    border: none;
    text-align: left;
  }

  .table_header {
    border: none;
  }

  .empty_column {
    background-color: $gray-bg;
    width: 0.9cm;
    border: none;
  }

  .nummer_column_header {
    width: 1.8cm;
  }

  .datum_verpacker_column_header {
    width: 2.7cm;
  }
}
