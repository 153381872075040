.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

$gray-bg: #bfbfbf;
$dark-gray-bg: #808080;

.abmusterung_page_container {
  font-size: 0.8rem;
  font-family: monospace;
  letter-spacing: -0.025rem;
  padding: 0.5cm;
  margin: 0;
  border: none !important;
  width: 21cm;
  height: 29.7cm;
}

.abmusterung_table {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  border-collapse: collapse;
  white-space: nowrap !important;
  border: 2px solid black;

  tbody > tr:first-child {
    tr,
    th,
    td {
      padding: 0 !important;
    }
  }

  thead {
    tr {
      td:first-child {
        min-width: 4.45cm !important;
      }
      td:nth-child(2) {
        min-width: 3.969cm !important;
      }
      td:nth-child(3) {
        min-width: 2.88cm !important;
      }
      td:nth-child(4) {
        min-width: 1.402cm !important;
      }
      td:nth-child(5) {
        min-width: 3.704cm !important;
      }
      td:nth-child(6) {
        min-width: 3.57cm !important;
      }
    }
  }

  td,
  th {
    margin: 0 !important;
    padding: 0.58rem 0.1rem !important;
    vertical-align: middle !important;
    box-shadow: none !important;
    font-size: 0.8rem;
  }

  td:nth-child(2) {
    text-align: center !important;
  }

  th {
    text-align: center !important;
  }

  .right {
    text-align: right !important;
  }

  .left {
    text-align: left !important;
  }

  .border {
    border: 1px solid black;
  }

  .border_bottom {
    border-bottom: 1px solid black;
  }

  .border_top {
    border-top: 1px solid black;
  }

  .fat_border_right {
    border-right: 2px solid black;
  }

  .fat_border_top {
    border-top: 2px solid black;
  }

  .section_row {
    height: 0;
    border-bottom: 2px solid black;
    padding: 0 !important;
  }

  .spacer_row {
    height: 1rem !important;
    td,
    th {
      padding: 0 !important;
    }
  }

  .flex {
    display: flex;
    align-items: center !important;
  }

  .fat_border_dark_bg {
    border: 2px solid black;
    background-color: $dark-gray-bg;
    color: white;
    padding: 0 2rem;
  }

  .dotted_bottom_border_gray_bg {
    text-align: left !important;
    border-bottom: 3px dotted black;
    background-color: $gray-bg;
  }

  .checkliste_header {
    border: 2px solid black;
    background-color: $dark-gray-bg;
  }

  div.check_box {
    height: 1.5rem;
    border: 1px solid black;
    margin: 0 0.5rem;
    padding: 0;
  }

  .footer_hinweis {
    text-align: left !important;
    font-style: italic;
    text-decoration: 2px underline;
    border: 3px solid black;
  }
}
