.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

.stapelselection_button {
  position: fixed;
  bottom: 25px;
  right: 150px;
}

@media print {
  tr,
  td,
  th {
    page-break-inside: avoid;
    break-inside: avoid;
    white-space: nowrap;
    width: min-content;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button, .stapelselection_button {
    display: none;
  }
}

.etikett_tray_page_container {
  margin: 0 !important;
  width: 29.7cm;
  height: 21cm;
  padding: 1cm;
}

.etikett_tray_table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: 2px solid black;

  .right {
    text-align: right !important;
  }

  .small {
    font-size: 1.4rem !important;
  }

  .schicht_frueh {
    @extend .small;
    width: 189px;
  }

  .large {
    font-size: 2.2rem !important;
  }

  .larger {
    font-size: 3.8rem !important;
  }

  .tall {
    height: 4.2rem !important;
  }

  tr,
  td,
  th {
    min-width: 5rem;
    height: 1rem !important;
  }

  tr,
  td,
  th {
    padding: 0.2rem !important;
    margin: 0 !important;
    vertical-align: middle !important;
    font-size: 1.5rem !important;
    font-weight: bold !important;
    font-family: monospace;
    text-align: center !important;
    letter-spacing: -0.03rem;
    white-space: nowrap;
  }

  .double_underline {
    text-decoration: 2px underline double;
  }

  div.check_box {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid black;
    margin: 0 auto;
  }

  div.long_box {
    width: 15rem;
    height: 1.5rem;
    border: 1px solid black;
    margin: 0 auto;
  }

  div.big_box {
    width: 100%;
    height: 8rem;
    border: 1px solid black;
    margin: 0 auto;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
  }

  .row {
    display: flex;
  }

  .sonax_art_nr_header,
  .farbe_header {
    @extend .large;
    @extend .right;
  }

  .palettenhoehe_header {
    @extend .large;
    @extend .right;
    @extend .double_underline;
    padding-left: 1rem !important;

    width: 450px;
  }
}
