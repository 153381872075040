.current_date {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 40px;
}

.protokol_view {
  margin-top: 20px;
}

.header {
  display: flex;
  justify-content: center;
  text-align: center;
}

.content {
  margin-top: 20px;
  width: 100%;

  table {
    width: 100%;
  }

  thead {
    background-color: rgb(180, 180, 180);

    th {
      text-align: center;
    }
  }

  th,
  td {
    padding: 0.1rem !important;
    vertical-align: middle !important;
    border: 1px solid black;
  }

  .center {
    text-align: center !important;
  }

  .right {
    text-align: right !important;
  }

  .borderless {
    border: none !important;

    tr,
    td,
    th {
      border: none !important;
    }
  }
}

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}
