.print_button {
  position: fixed;
  bottom: -89px;
  right: -500px;
}

@media print {
  tr,
  td,
  th {
    page-break-inside: avoid;
    break-inside: avoid;
    white-space: nowrap;
    width: min-content;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}

.grid_2x8 {
  width: 21cm;
  height: 29.7cm;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 0.5rem 0;
  padding: 0.5cm;
}

$font_large: 1rem;
$font_larger: 2.1rem;

.etikett_karton_table {
  border-collapse: collapse;

  tr,
  th,
  td {
    padding: 0 2rem 0 0 !important;
    margin: 0 1rem !important;
    vertical-align: middle !important;
    letter-spacing: -0.03rem;
    white-space: nowrap;
  }

  thead th {
    font-size: $font_larger !important;
    line-height: 2.2rem !important;
    font-weight: bold !important;
    font-style: italic;
    border-bottom: 3px solid black;
  }

  thead td {
    font-size: $font_large !important;
    line-height: 1.8rem !important;
    font-weight: bold !important;
  }

  .bold {
    font-weight: bold !important;
  }

  .bolder {
    font-weight: 1000 !important;
  }

  .right {
    text-align: right !important;
    font-size: small !important;
    line-height: 1.2rem !important;
  }

  .stueckzahl {
    @extend .bolder;
    font-size: $font_larger !important;
    line-height: 2.2rem !important;
  }

  .last_row {
    td, th {
      width: max-content;
    }
  }

  tbody th, tbody td {
    line-height: 1.38rem !important;
    font-size: 1.4rem;
  }

  .shb_header {
    font-size: $font_large !important;
    font-weight: 1000 !important;
    font-style: normal;
    border-bottom: 3px solid black;
  }
}
