.header {
  margin-top: 30px;
  width: 100%;
  display: grid;
  justify-content: center;

  h2 {
    text-decoration: underline;
    text-decoration-style: double;
  }
}

.header__datetime {
  position: absolute;
  right: 0;
  text-align: right;
}

.content {
  width: 100%;

  th, td {
    border: 1px solid black;
  }
}

.table_header {
  background-color: rgb(180, 180, 180);
}

.centered_cell {
  text-align: center !important;
}

.empty_row {
  background-color: #ddd;
}

.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  tr {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  body {
    background-color: white !important;
  }

  .print_button {
    display: none;
  }
}
