.grid_container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.action_button_cell {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  column-gap: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.grid_container__header {
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  column-gap: 5px;
}

.grid_container__header_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  width: 100%;
}

.grid_container__header_actions label {
  margin: 0;
}

.grid_container__header h1 {
  margin-top: 0px;
}

.grid_container__header button {
  align-self: baseline;
}
