.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  .stellblatt_page_container {
    tr,
    td,
    th {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    body {
      background-color: white !important;
    }

    .print_button {
      display: none;
    }
  }
}

$cell_width: 0.4589cm;
$gray_bg: #d9d9d9;
$pink_bg: #f2dcdb;

.stellblatt_page_container {
  width: 21cm;
  height: 29.7cm;
  padding-left: 1.8cm;
  padding-right: 1.3cm;
  padding-top: 1.1cm;
  padding-bottom: 0.5cm;
  font-family: monospace;
  letter-spacing: -0.03rem;

  .stellblatt_title {
    height: 1.1cm;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;


    .logo {
      justify-self: end;
      img {
        width: 2.8cm;
      }
    }

    span {
      font-weight: bold;
    }

    .stellblatt_title_word {
      justify-self: center;
      text-decoration: underline;
    }
  }
}

.stellblatt_table {
  border-collapse: collapse;
  border: 2px solid black;
  padding: 0;

  td,
  th {
    border: 1px solid black;
    padding: 0 0.02cm !important;
    margin: 0 !important;
    width: $cell_width !important;
    min-width: $cell_width !important;
    max-width: $cell_width !important;
    vertical-align: middle !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    font-size: 0.29cm;
  }

  tr td {
    font-weight: 500;
    text-align: center !important;
  }

  .gray_bg {
    background-color: $gray_bg;
  }

  .gray_bg_large {
    background-color: $gray_bg;
    text-align: center;
    font-size: 0.315cm;
  }

  .gray_bg_center {
    background-color: $gray_bg;
    text-align: center;
  }

  .gray_bg_small {
    font-size: 0.26cm;
    text-align: center;
    background-color: $gray_bg;
  }

  .gray_bg_right_medium {
    font-size: 0.275cm;
    text-align: right;
    background-color: $gray_bg;
  }

  th.pink_header {
    background-color: $pink_bg;
  }

  th.pink_header_bold {
    font-weight: bold;
    text-align: center;
    background-color: $pink_bg;
  }

  th.pink_header_bold_left {
    font-weight: bold;
    background-color: $pink_bg;
  }

  th.pink_header_bold_small {
    font-size: 0.25cm;
    font-weight: bold;
    text-align: center;
    background-color: $pink_bg;
  }

  th.pink_header_left {
    font-weight: normal;
    background-color: $pink_bg;
  }

  .gray_bg_dashed_border {
    background-color: $gray_bg;
    border: 2px dashed black;
  }

  .dashed_border {
    border: 2px dashed black;
  }

  .dashed_border_right {
    border-right: 2px dashed black;
  }

  .dashed_border_left {
    border-left: 2px dashed black;
  }

  .hinweise {
    background-color: white;
    height: 2.2cm;

    td {
      font-weight: normal;
      text-align: left !important;
      vertical-align: top !important;
    }
  }

  .abstaende {
    background-color: $gray_bg;
    padding-left: 2rem !important;
  }

  .kopf_messer_form {
    font-size: 0.6rem;
    text-align: center;
    background-color: $gray_bg;
    border: none;
  }

  .gray_bg_border_left {
    border: none;
    border-left: 2px solid black;
    background-color: $gray_bg;
  }

  .center_small {
    font-size: 0.6rem;
    text-align: center;
  }

  .no_border {
    border: none !important;
  }

  .no_border_y {
    border-top: none !important;
    border-bottom: none !important;
  }

  .spacer_cells {
    width: $cell_width !important;
    border: none !important;
    padding: none !important;
    margin: none !important;
  }

  img.blasdornspitze_image {
    width: 2.15cm;
  }

  img.abschlagbacken_image {
    width: 2.53cm;
  }

  img.abstaende_image {
    position: static;
    width: 3.8cm;
  }
}
