.print_button {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

@media print {
  .mlpprint_page_container {
    tr {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    th, td {
      white-space: normal !important;
      word-wrap: break-word !important;
    }

    thead {
      display: table-header-group;
    }

    body {
      background-color: white !important;
    }

    .print_button {
      display: none;
    }
  }
}

.mlpprint_page_container {
  padding-top: 1.5cm;
  padding-bottom: 1.5cm;
  padding-left: 1.3cm;
  padding-right: 0.7cm;
  width: 21cm;
  height: 29.7cm;
  font-size: 0.8rem;
  font-family: monospace;
  letter-spacing: -0.03rem;

  .mlp_title {
    display: flex;
    justify-content: space-between;
  }

  .mlp_title_datetime {
    display: flex;
    gap: 2rem;
    margin-right: 3rem;
  }

  .mlp_table {
    width: 100%;
    margin-top: 1rem;
    border: 2px solid black;
    padding: 0;
    min-height: 26cm;
    min-width: 19cm;

    border-collapse: collapse;

    tr {
      td,
      th {
        border-collapse: collapse;
        box-sizing: border-box;
        padding: 0.12rem;
        font-weight: normal;
      }

      td {
        font-weight: bold;
        vertical-align: middle;
      }

      th {
        border-left: 2px solid black;
      }
    }

    .mlp_table_header {
      font-size: 1.3rem;
      border-left: none;
      height: 0.9cm;

      td {
        border: none;
        font-size: 1rem;
      }

      th {
        vertical-align: middle;
        border: none;
        font-weight: bold;
      }
    }

    th.vorgaben_header {
      border: 4px solid black;
      border-top: none;
      text-decoration: 4px underline;
      text-align: center;
      vertical-align: middle;
      padding: 0.2rem 0;
    }

    th.blasmaschine_nr_header,
    th.stretcher_nr_header {
      font-size: 1.3rem;
      text-align: right;
      border-bottom: none;
      padding-top: 0.2rem;
    }

    th.pau_nr_header {
      padding-top: 0.2rem;
      width: 9.3rem;
    }

    .stretcher_nr {
      width: 8rem;
    }

    td.pau_nr {
      font-weight: normal;
    }

    td.blasmaschine_nr,
    td.stretcher_nr,
    td.pau_nr {
      font-size: 1.3rem;
      text-align: center;
      border-bottom: 3px solid black;
      padding-top: 0.2rem;
    }

    tr.spacer_row {
      height: 0.7rem;
    }

    th.liefertermin_header {
      width: 12.8rem;
    }

    td.liefertermin {
      border-bottom: 2px solid black;
      text-align: center;
    }

    th.fl_typ_header,
    th.farbe_bez_header {
      height: 4rem;
    }

    th.farbe_bez_header {
      vertical-align: middle;
    }

    td.fl_typ,
    td.farbe_bez {
      height: 4rem;
      border-bottom: 2px solid black;
    }

    td.farbe_bez {
      text-align: center;
    }

    td.typ_spez {
      border-left: 2px solid black;
      border-bottom: 2px solid black;
      width: 6.2rem;
    }

    th.volumen_ht_header {
      text-align: right;
      vertical-align: middle;
    }

    td.volumen {
      text-align: center;
      vertical-align: middle;
      border-bottom: 2px solid black;
      width: 6.2rem;
    }

    td.einfaerbung {
      border-bottom: 2px solid black;
      border-right: 2px solid black;
      text-align: center;
      width: 8rem;
    }

    td.bemerkung {
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      font-weight: normal;
      text-align: left !important;
      height: 2rem;
      max-width: 12.4rem;
      overflow: hidden;
    }

    td.bemerkung_text {
      border-bottom: 2px solid black;
      text-align: left !important;
      max-width: 5rem;
      overflow: hidden;
    }

    th.werkstoff_typ_bezeichnung_header {
      text-align: right;
    }

    td.werkstoff_typ_bezeichnung {
      border: 4px solid black;
      border-right: none;
    }

    tr.grid {
      td {
        text-align: center;
        border-right: 2px solid black;
      }

      th:nth-child(1),
      td:nth-child(2) {
        border: 2px solid black;
      }

      td:nth-child(3) {
        border-bottom: 2px solid black;
      }
    }

    tr.grid_bunt_header {
      th:nth-child(1) {
        vertical-align: middle;
        border-top: 2px solid black;
      }

      th:nth-child(2),
      th:nth-child(3) {
        background-color: #c6c3c6;
        text-align: center;
        border-top: 2px solid black;
        border-right: 2px solid black;
      }
    }

    tr.grid_bunt {
      td {
        text-align: center;
      }

      td:nth-child(1),
      td:nth-child(2) {
        border-left: 2px solid black;
        border-right: 2px solid black;
      }
    }

    td.verpackungs_einheit_bezeichnung {
      font-size: x-large;
    }

    th.flaschen_pro_vpe_header {
      vertical-align: middle;
      border-bottom: 2px solid black;
    }

    th.karton_vp_header {
      text-align: right;
      font-size: small;
      text-decoration: 2px underline;
      border-right: 2px solid black;
    }

    td.tray_vp_header {
      border-top: 2px solid black;
    }

    th.zw_lage_deckel_header {
      font-size: small;
      text-decoration: none;
      text-align: right;
      border-right: 2px solid black;
    }

    td.zw_lage {
      border-bottom: 2px dotted black;
    }

    tr.centered_text_row_data {
      td {
        text-align: center;
      }
    }

    td.kunde_name {
      font-size: larger;
      text-align: left !important;
      border-bottom: 2px solid black;
    }

    th.empfaenger_header {
      text-align: right;
      padding-right: 2rem;
    }

    th.bemerkung_header {
      font-style: italic;
      text-decoration: 2px underline;
      border-top: 2px solid black;
    }

    td.empty_gray_triple_cell {
      background-color: #e7e3e7;
      border: 2px solid black;
    }

    td.palettenhoehe {
      border-right: 2px solid black;
      text-align: center;
      font-size: larger;
      padding: 0;
    }
  }

  .abschnitt {
    border-top: 2px solid black;
  }

  .border_bottom {
    border-bottom: 2px solid black;
  }

  .border_right {
    border-right: 2px solid black;
  }
}

